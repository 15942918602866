// 续缴社保
import React, { useState, useEffect, useCallback } from 'react';
import { Footer } from '../component/footer';
import { Header } from '../component/header';
import { Notice } from '../component/notice';
import { Contact } from '../component/contacts';
import { CustomeCard } from '../component/custome-card';

import { SelectRightArrow } from '../component/select-right-arrow';

import { Form, Input, Radio, Space, Button, Stepper, Toast, Image } from 'antd-mobile';

import { useNavigate  } from 'react-router-dom';
import {get} from '../common/fetch';
import { getFormatData, toast} from '../common/utils';
import { useSelector } from 'react-redux';
import { AddOutline } from 'antd-mobile-icons'

import './style.scss';

const IMGS = {
    mingxi: require('../images/shebaomingxi.png'),
};

const isCity = (num) => {
    return Number(num) === 1 || Number(num) === 2;
};

const itemColumns_city = [[
    { label: '社保+公积金', value: 5152},
    { label: '社保+公积金+个税', value: 515253},
]];

// const itemColumns_country = [[
//     { label: '社保', value: 5100},
//     { label: '社保+公积金', value: 5152},
//     { label: '社保+公积金+个税', value: 515253},
// ]];

export const SocialSecurityRenew = () => {
    const { USER_ID } = useSelector(state=>state.userInfo);
    const [form] = Form.useForm();
    const [personListData, setPersonListData] = useState([]);
    const [personListAllInfoData, setPersonListAllInfoData] = useState([]);
    const [shebaoMin, setShebaoMin] = useState(0);
    const [gjjMin, setGjjMin] = useState(0);
    const [shebaoMax, setShebaoMax] = useState(0);
    const [gjjMax, setGjjMax] = useState(0);
    // const [shebaoVal, setShebaoVal] = useState(0);
    // const [gjjVal, setGjjVal] = useState(0);
    const [userId, setUserId] = useState(null);
    const [isShowTax, setIsShowTax] = useState(false);
    const [monthList, setMonthList] = useState([]);
    const [selectUserItemFlag, setSelectUserItemFlag] = useState(0)

    const navigate = useNavigate();

    useEffect(() => {
        loadPerson()
    }, [])
    const loadPerson = () => {
        get('person/list', {userId: USER_ID}).then((res) => {
            if (res.code === 200 && res.data.length > 0){
                const formatArr = res.data.map((ele) => {
                    return {
                        label: ele.fullName,
                        value: ele.birthType+'_'+ele.id
                    };
                });
                setPersonListData([formatArr]);

                setPersonListAllInfoData( res.data)
            }
        }).catch((e)=>{
            console.log(e);
        });
    }
    useEffect(() => {
        get('base/list', {cityId: 1100}).then((res) => {
            if (res.code === 200 && res.data){
                res.data.forEach((ele) => {
                    if(ele.socialType === 51){
                        setShebaoMin(ele.baseMinValue / 100);
                        setShebaoMax(ele.baseMaxValue / 100);
                        const formatArr = ele?.monthList.map((ele) => {
                            return {
                                label: ele,
                                value: ele
                            };
                        });
                        form.setFieldValue('secuBaseValue', ele.baseMinValue / 100);
                        setMonthList([formatArr]);
                    } else {
                        setGjjMin(ele.baseMinValue / 100);
                        setGjjMax(ele.baseMaxValue / 100);
                        form.setFieldValue('housingBaseFee', ele.baseMinValue / 100);
                    }
                });
            }
        });
    }, []);

    const changeGjjValHandler = (val) => {
        console.log(val);
        form.setFieldValue('housingBaseFee', val);

        // 2024-03-19变更：公积金和社保的关联去掉
        // const tmpVal = val > shebaoMin
        //     ? val > shebaoMax
        //         ? shebaoMax
        //         : val
        //     : shebaoMin;

        // form.setFieldValue('secuBaseValue', tmpVal);
    };

    const secuPersonChangeHandler = (val) => {
        setUserId(val[0]?.split('_')[0]);
        // editItems
        editItems(val[0]?.split('_')[1])
    };

    const editItems = (userId) => {
        const selectUser = personListAllInfoData.filter(item => item.id === +userId)
        if(selectUser?.length) {
            setSelectUserItemFlag(selectUser[0]?.itemFlag)
        }
    }

    const itemColumns_country = useCallback(() => {
        if(+selectUserItemFlag === 1) {
            return ([[
                { label: '社保', value: 5100},
                { label: '社保+公积金', value: 5152},
                { label: '社保+公积金+个税', value: 515253},
            ]])
        }
        if(+selectUserItemFlag === 2) {
            return ([[
                { label: '社保', value: 5100},
                { label: '公积金', value: 5200},
                { label: '社保+公积金', value: 5152},
                { label: '社保+公积金+个税', value: 515253},
            ]])
        }
        return ([[
            { label: '社保+公积金', value: 5152},
            { label: '社保+公积金+个税', value: 515253},
        ]])

    }, [selectUserItemFlag])

    const itemChangeHandler = (val) => {
        setIsShowTax(val[0] === 515253);
    };

    const checkValues = (values) => {
        if(!userId) {
            toast('请选择参保人');
            return false;
        }
        if(!values.startDate) {
            toast('请选择开始月份');
            return false;
        }
        if(!values.endDate) {
            toast('请选择结束月份');
            return false;
        }
        if(!values.item) {
            toast('请选择缴费项目');
            return false;
        }
        return true;
    };
 
    const onFinish = async() => {
        const values = form.getFieldsValue();
        if(!checkValues(values)) { return; }

        const formatData = getFormatData(values);
        console.log('values==', formatData);
        formatData.secuPersonId = formatData.secuPersonId.split('_')[1];
        formatData.userId = USER_ID;
        formatData.isArrearsPay = 0; // 0：续费，1: 补缴

        get('order/cal', formatData).then((res) => {
            if (res.code === 200 && res.data){
                // Toast.show({
                //     icon: 'success',
                //     content: '保存成功',
                // });
                navigate('/notice', { state: {data: res.data,  formatData: formatData, router: '/renew/socialSecurityRenewConfirm'}});
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '保存失败',
                });
            }
        });

    };

    const goAdd = () => {
        navigate('/AddPerson', { state: {isEdit: false}});
    }

    const getItemColumns_country = itemColumns_country()

    return (
        <div>
            <Header />
            <Notice />
            <div className='marginT soical_form' style={{paddingBottom: '80px'}}>
                <CustomeCard  title='续费，社保/公积金/个税'>
                   
                    <Form
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            initialValue={1100}
                            name='cityId'
                            label='缴费城市(仅支持北京)'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                            className='item_city'
                        >
                            <div>北京市</div>
                        </Form.Item>
                        <Form.Item
                            name='secuPersonId'
                            label={<span>选择参保人&nbsp;&nbsp;<span style={{ color: '#1677ff'}} onClick={goAdd}>(<AddOutline />添加)</span></span>}
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '40%'}}
                            layout='horizontal'
                        >
                            <SelectRightArrow 
                                title={'选择参保人'}
                                onChange={secuPersonChangeHandler}
                                basicColumns={personListData}
                                type='person'
                                handleReloadPerson={loadPerson}
                            />
                        </Form.Item>
                        <Form.Item
                            name='startDate'
                            label='开始月份（包含）'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                        >
                            <SelectRightArrow 
                                title={'选择开始月份'}
                                basicColumns={monthList}
                            />
                        </Form.Item>
                        <Form.Item
                            name='endDate'
                            label='结束月份（包含）'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                        >
                            <SelectRightArrow 
                                title={'选择结束月份'}
                                basicColumns={monthList}
                            />
                        </Form.Item>
                        <Form.Item
                            initialValue={0}
                            name='isNewSocial'
                            label='是否新参保（说明）'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '40%'}}
                            layout='horizontal'
                        >
                            <Radio.Group>
                                <Space direction='horizontal'>
                                    <Radio value={0} style={{fontSize: '12px'}}>缴存过</Radio>
                                    <Radio value={1}>新参保</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name='secuBaseValue'
                            label={`社保基数(${shebaoMin}-${shebaoMax})`}
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                            extra={
                                <div>
                                  元
                                </div>
                            }
                        >
                            <Input
                                type='number'
                                min={shebaoMin}
                                max={shebaoMax}
                                // readOnly
                                // placeholder='请输入内容'
                                style={{ '--text-align': 'right' }} 
                            />
                        </Form.Item>
                        <Form.Item
                            name='housingBaseFee'
                            label={`公积金基数(${gjjMin}-${gjjMax})`}
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '50%'}}
                            layout='horizontal'
                            extra={
                                <div>
                                  元
                                </div>
                            }
                        >
                            <Input
                                // placeholder='请输入内容'
                                style={{ '--text-align': 'right' }}
                                onChange={changeGjjValHandler}
                            />
                                    
                        </Form.Item>
                        <Form.Item
                            name='item'
                            label='续缴项目'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '50%'}}
                            layout='horizontal'
                        >
                            <SelectRightArrow 
                                title={'请选择'}
                                // basicColumns={isCity(userId) ? itemColumns_city : getItemColumns_country}
                                basicColumns={getItemColumns_country}
                                onChange={itemChangeHandler}
                            />
                        </Form.Item>
                        {
                            isShowTax && <Form.Item
                                name='personalTaxFee'
                                label='个税金额(税额:10-200元)'
                                rules={[{ required: true }]}
                                style={{'--prefix-width': '50%'}}
                                layout='horizontal'
                                initialValue={10}
                            >
                                <Stepper
                                    min={10}
                                    max={200}
                                    digits={0}
                                    inputReadOnly
                                />
                            </Form.Item>
                        }
                        
                    </Form>
                </CustomeCard>
                <div className='marginT box_layer'>
                    <Button color='success' onClick={onFinish} className='button'>下一步，计算费用</Button>
                </div>
                <div className='marginT'>
                    <CustomeCard  title='北京社保续费说明：'>
                        <Image src={IMGS.mingxi} width={350} className='policy_img'/>
                    </CustomeCard>
                </div>
                {/* 备案号 */}
                <Contact />
            </div>
            <Footer />
        </div>
    );
};