// 续缴社保
import React from 'react';
import { Image } from 'antd-mobile';
import './style.scss';

const IMGS = {
    person: require('../images/person.png'),
};

export const DomicilePage = () => {
    return (
        <div className='policy_page_box'>
            <div className='big_title'>养老政策</div>
            <Image src={IMGS.person} width={180} className='policy_img'/>,
            <div className='policy_con'>
                <p className='policy_title'>非京籍户口:</p>
                <p>1、达到法定退休年龄(男士60周岁、女50周岁或干部55周岁)社保</p>
                <p>2、全国累计缴纳社保至少满足15年，在京一般帐户下累计缴纳至少满足10年</p>
                <p>3、最后社保关系必须在北京</p>
            </div>
        </div>
    )
}