// 续缴社保
import React from 'react';
import { Contact } from '../component/contacts';
import { Image } from 'antd-mobile';
import './style.scss';
const IMGS = {
    mingxi: require('../images/shebaomingxi.png'),
};

export const Detail = () => {
    return (
        <div className='policy_page_box'>
            <div className='big_title'>缴费明细</div>
            <div className='detail_title' style={{fontSize: '18px'}}>北京社保续费说明：</div>
            <Image src={IMGS.mingxi} width={350} className='policy_img'/>
            {/* <div>
                <div>
                    <p>项目</p>
                    <p>基数</p>
                    <p>个人</p>
                    <p>公司</p>
                </div>
                <div>
                    <p>养老保险</p>
                    <p>6821.00</p>
                    <p>545.68<br />(8.00%)</p>
                    <p>1091.36<br />(12.00%)</p>
                </div>
                <div>
                    <p>医疗保险</p>
                    <p>6821.00</p>
                    <p>136.42<br />(2.00%)</p>
                    <p>613.89<br />(9.00%)</p>
                </div>
                <div>
                    <p>大病/补充/大额医疗</p>
                    <p>3.00</p>
                    <p>3.00<br />(100.00%)</p>
                    <p>--</p>
                </div>
                <div>
                    <p>失业保险</p>
                    <p>6821.00</p>
                    <p>34.11<br />(0.50%)</p>
                    <p>34.11<br />(0.50%)</p>
                </div>
                <div>
                    <p>工伤保险</p>
                    <p>6821.00</p>
                    <p>--</p>
                    <p>27.28<br />(0.40%)</p>
                </div>
                <div>
                    <p>生育保险</p>
                    <p>6821.00</p>
                    <p>--</p>
                    <p>54.57<br />(0.80%)</p>
                </div>
                <div>
                    <p>社保（小计）</p>
                    <p>6821.00</p>
                    <p>--</p>
                    <p>50.61<br />(0.80%)</p>
                </div>
            </div> */}
            {/* 备案号 */}
            <div style={{ paddingTop: '30px'}}>
                <Contact />
            </div>
        </div>
    )
}