// 补缴社保
import React, { useEffect, useState } from 'react';

import { Header } from '../component/header';
import { Notice } from '../component/notice';
import { CustomeCard } from '../component/custome-card';
import { Footer } from '../component/footer';

import { Collapse, Divider  } from 'antd-mobile';

import { formatDate,socialType } from '../common/utils';
import { useSelector, useDispatch } from 'react-redux';
import {get} from '../common/fetch';
import { APPID, SCOPE, getUrlParms, isWx, getCookies } from '../common/utils';
import { saveUser } from '../redux/userInfoSlice';

import './style.scss';

// const list = [
//     {
//         buyTime: '2023-02-18',
//         type: '续费',
//         status: '已参保',
//         name: '张三',
//         orderNum: '123456780',
//         cityName: '北京市'
//     },
//     {
//         buyTime: '2023-02-18',
//         type: '续费',
//         status: '已参保',
//         name: '李四',
//         orderNum: '123456780',
//         cityName: '北京市'
//     },
//     {
//         buyTime: '2023-02-18',
//         type: '续费',
//         status: '已参保',
//         name: '赵一一',
//         orderNum: '123456780',
//         cityName: '北京市'
//     }
// ];

export const PaymentList = () => {
    const dispatch = useDispatch();
    const [paymentList, setPaymentList] = useState([]);
    const { USER_ID } = useSelector(state=>state.userInfo);
    // const openid = getCookies('openid');
    // const nickname = getCookies('nickname')
    // const headimgurl = getCookies('headimgurl')
    const userInfo = sessionStorage.getItem('userInfo')
    const { headImgUrl, nickName, userId } = userInfo? JSON.parse(userInfo) : {}  
    
    useEffect(() => {
        if(userId && nickName && headImgUrl) {
            dispatch(saveUser({
                id: userId,
                nickName: nickName,
                headImgUrl: headImgUrl
            }));
            return;
        } else if(isWx()) {
            fetchWxCode()
        }
    }, []);

    const fetchWxCode = () => {
        try {
            const REDIRECT_URI='https://m.qingwaguagua.com/paymentList'
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`;
            // window.location.href = ('http://localhost:3000?a=1&code=1234');
            sessionStorage.setItem('times', 1);
        } catch(error) {
            console.log('error-', error);
        }
    }

    useEffect(() => {
        if(userId && nickName && headImgUrl) {
            return;
        } else {
            let code = getUrlParms(location.href, 'code');
            if(code) {
                localStorage.setItem('code', code )
                get('weixin/invoke', {code: code}).then((res) => {
                    if (res.code === 200 && res.data){
                        sessionStorage.setItem('userInfo',JSON.stringify( res.data))
                        dispatch(saveUser({
                            id: res.data.userId,
                            nickName: res.data.nickName,
                            headImgUrl: res.data.headImgUrl
                        }));
                    } else{
                        sessionStorage.removeItem('times');
                        isWx() && fetchWxCode()
                    }
                }).catch(() => {
                    sessionStorage.removeItem('times');
                    isWx() && fetchWxCode()
                });
            } else {
                sessionStorage.removeItem('times');
                isWx() && fetchWxCode()
            }
        }
        
    },[window.location.href]);

    useEffect(() => {
        if(userId && nickName && headImgUrl) {
            get('order/find', {userId: USER_ID}).then((res) => {
                if (res.code === 200 && res.data){
                    setPaymentList(res.data);
                } else {
                    setPaymentList([]);
                }
            });
        }
    }, []);

    const arraypayMay = {
        0: '续费',
        1: '补缴'
    };

    return (
        <>
            <Header />
            {/* 公告 */}
            <Notice />
            <div className='marginT' style={{ paddingBottom: '80px'}}>
                <CustomeCard  title='缴费记录'>
                    <Collapse defaultActiveKey={['0']}>
                        {
                            paymentList.map((item, index) => {
                                return (
                                    <Collapse.Panel key={index} title={
                                        <div className='list_collaps_title'>
                                            <p>{formatDate(item.buyTime, 'yyyy-MM-dd')}</p>
                                            <p>{arraypayMay[item.isArreasPay]}（{item.cityName}）</p>
                                            <p>{item.orderStatusName}</p>
                                            <p>{item.fullName}</p>
                                        </div>
                                    }>
                                        <div className='list_collaps_cont'>
                                            <div className='flexCenter cont_item'>
                                                <span>订单编号</span>
                                                <span>{item.orderNum}</span>
                                            </div>
                                            {
                                                !!item.orderDetailVos.length && item.orderDetailVos.map((v,k) => {
                                                    return (
                                                        <div  key={k}>
                                                            <div className='flexCenter cont_item'>
                                                                <span className='l1'>{v.socialMonth}</span>
                                                                <span className='l2'>{socialType[v.socialType]}{v.socialType ==53 ? '税额':''}费 {!!v.baseValue && <span>（基数{v.baseValue / 100}）</span>}</span>
                                                                <span className='color_green'>{v.detailStatusName} </span>
                                                                <span>￥{v.socialSecuFee / 100}</span>
                                                            </div>
                                                            {
                                                                item.isArrearsPay === 1 && <div className='flexCenter cont_item'>
                                                                    <span className='l1'>{v.socialMonth}</span>
                                                                    <span className='l2'><span className='blue_color'>补缴</span>滞纳金</span>
                                                                    <span className='color_green'>{v.detailStatusName} </span>
                                                                    <span>￥{v.compenFee / 100}</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                            {/* <div className='flexCenter cont_item'>
                                                <span>2023-02</span>
                                                <span>公积金费（基数4896）</span>
                                                <span className='color_green'>已完成 </span>
                                                <span>￥2168.5</span>
                                            </div> */}
                                            <Divider  />
                                            <div className='flexCenter cont_item'><span>费用总额</span><span>￥{item.socialAllFee / 100}</span></div>
                                            <div className='flexCenter cont_item'><span>服务费合计</span><span> +￥{item.serviceSumFee / 100}</span></div>
                                            <div className='flexCenter cont_item'><span>支付通道费</span><span>+￥{item.wxPayFee / 100}</span></div>
                                            <div className='flexCenter cont_item'><span></span><span className='import_font'>实付金额 ￥{item.orderTotalFee / 100}</span></div>
                                            <Divider  />
                                            <div className='flexCenter cont_item'><span>创建时间 </span><span>{formatDate(item.buyTime, 'yyyy-MM-dd h:m:s')}</span></div>
                                            {
                                                !!item.payDate &&
                                                <div className='flexCenter cont_item'><span>付款时间 </span><span>{formatDate(item.payDate, 'yyyy-MM-dd h:m:s')}</span></div>
                                            }
                                        </div>
                                    </Collapse.Panel>
                                );
                            })
                        }
                    </Collapse>
                </CustomeCard>
            </div>
            <Footer />
        </>
    );
};