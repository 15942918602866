import React, { useEffect, useState } from 'react';

import {  NoticeBar } from 'antd-mobile';

import './style.css';

import { get } from '../../common/fetch';


export const Notice  = () => {
    const [notice, setNotice] = useState('');
 
    // useEffect(() => {
    //     get('base/text').then((res) => {
    //         if (res.code === 200 && res.data){
    //             if(res.data) {
    //                 let noticeList = res.data.filter(item => item.type === '1');
    //                 noticeList.length && setNotice(noticeList[0].text);
    //             }
    //         } else {
    //             setNotice('');
    //         }
    //     });
    // }, []);

    if(!notice) {
        return;
    }


    return (
        <div className='notice_box'>
            <NoticeBar content={notice} color='alert' style={{ width: '100%' }} />
        </div>
    );
};
