import React, { useEffect } from 'react';

import { Footer } from '../component/footer';
import { Header } from '../component/header';
import { Notice } from '../component/notice';
import { CustomeCard } from '../component/custome-card';

import { UserContactOutline, TextOutline, SetOutline, RightOutline  } from 'antd-mobile-icons';
import { useNavigate  } from 'react-router-dom';
import { APPID, SCOPE, getUrlParms, isWx, getCookies } from '../common/utils';
import { saveUser } from '../redux/userInfoSlice';
import { useDispatch } from 'react-redux';
import {get} from '../common/fetch';

import './style.scss';

const mineInfo = [
    {
        name: '参保人管理',
        page: 'insuredPersonnel',
        icon: <UserContactOutline width={28} height={28} />
    },
    {
        name: '缴费记录',
        page: 'paymentList',
        icon: <TextOutline width={28} height={28} />
    },
    {
        name: '帐户设置',
        page: 'account',
        icon: <SetOutline width={28} height={28} />
    },
];

const policyList = [
    {
        name: '续费政策'
    },
    {
        name: '补缴政策'
    },
    {
        name: '更多政策解读'
    }
];



export const MineHomePage = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onClick = (page) => {
        navigate(`/${page}`);
    };

    // const openid = getCookies('openid');
    // const nickname = getCookies('nickname')
    // const headimgurl = getCookies('headimgurl')

    const userInfo = sessionStorage.getItem('userInfo')
    const { headImgUrl, nickName, userId } = userInfo? JSON.parse(userInfo) : {}  

    useEffect(() => {
        if(userId && nickName && headImgUrl) {
            dispatch(saveUser({
                id: userId,
                nickName: nickName,
                headImgUrl: headImgUrl
            }));
            return;
        } else if(isWx()) {
            fetchWxCode()
        }
    }, []);

    const fetchWxCode = () => {
        try {
            const REDIRECT_URI='https://m.qingwaguagua.com/mine'
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`;
            // window.location.href = ('http://localhost:3000?a=1&code=1234');
            sessionStorage.setItem('times', 1);
        } catch(error) {
            console.log('error-', error);
        }
    }

    useEffect(() => {
        if(userId && nickName && headImgUrl) {
            return;
        }
        let code = getUrlParms(location.href, 'code');
        if(code) {
            localStorage.setItem('code', code )
            get('weixin/invoke', {code: code}).then((res) => {
                if (res.code === 200 && res.data){
                    sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                    dispatch(saveUser({
                        id: res.data.id,
                        nickName: res.data.nickName,
                        headImgUrl: res.data.headImgUrl
                    }));
                } else{
                    sessionStorage.removeItem('times');
                    isWx() && fetchWxCode()
                }
            }).catch(() => {
                sessionStorage.removeItem('times');
                isWx() && fetchWxCode()
            });
        } else {
            sessionStorage.removeItem('times');
            isWx() && fetchWxCode()
        }
    },[window.location.href]);

    
    return (
        <div>
            <Header />
            {/* 公告 */}
            <div className='marginT'>
                <Notice />
            </div>
            <div className='marginT'>
                <CustomeCard title='我的'>
                    {
                        mineInfo.map(item => {
                            return (
                                <div key={item.name} className='mine_item' onClick={() => {onClick(item.page);}}>
                                    {item.icon}
                                    <div className='mine_item_name'>{item.name}</div>
                                    <RightOutline />
                                </div>
                            );
                        })
                    }
                </CustomeCard>
            </div>
            <div className='marginT'>
                <CustomeCard title='政策解读'>
                    {
                        policyList.map(item => {
                            return (
                                <div key={item.name} className='mine_item'>
                                    <div className='mine_policy_itme_name'>{item.name}</div>
                                    <RightOutline />
                                </div>
                            );
                        })
                    }
                </CustomeCard>
            </div>
            <Footer />
        </div>
    );
};