// 续缴社保
import React from 'react';
import { Image } from 'antd-mobile';
import './style.scss';

const IMGS = {
    house: require('../images/house.png'),
};

export const HousePage = () => {
    return (
        <div className='policy_page_box'>
            <div className='big_title'>买房政策</div>
            <Image src={IMGS.house} width={200} className='policy_img'/>,
            <div className='policy_con'>
                <p className='policy_title'>非京籍户口在京购房:</p>
                <p>连续60个月(含)以上在京社保缴纳记录或连续60个月(含)以上个人所得税缴纳记录 ，(申请月的上一个月开始往前推算60个月连续，且57个月个税不能为0)</p>
                <p>注：因工作调动及单位原因造成断缴，5年内累计补缴不得超过3个月</p>
            </div>
        </div>
    )
}