import './App.scss';
import React, { useEffect, useState } from 'react';

// import { Button } from 'antd-mobile';
// import { Link } from 'react-router-dom';

import { Footer } from './component/footer';
import { Header } from './component/header';
import { Contact } from './component/contacts';
import { Notice } from './component/notice';
import { Policy } from './component/policy';
import { Tour } from './component/tour';
import { useDispatch } from 'react-redux';
import { saveUser } from './redux/userInfoSlice';

import { Card, Toast, DotLoading, Image } from 'antd-mobile';
// import { AntOutline, BillOutline, AppOutline } from 'antd-mobile-icons';

import { useNavigate  } from 'react-router-dom';

import { APPID, REDIRECT_URI, SCOPE, getUrlParms, isWx } from './common/utils';

import { get } from './common/fetch';

const IMGS = {
    shebao: require('./images/shebao.png'),
    gongjijin: require('./images/gongjijin.png'),
    xinchou: require('./images/xinchou.png')
    
};

const iconInfoList = [
    {
        name: <div className='item_block_font'><p>社保服务</p><p><span >39元</span>月起</p></div>,
        icon: <Image src={IMGS.shebao} width={65} height={65} />,
        page: '/renew/socialSecurityRenew'
    },
    {
        name: <div className='item_block_font'><p>公积金代缴</p><p><span>39元</span>月起</p></div>,
        icon: <Image src={IMGS.gongjijin} width={65} height={65} />,
        page: '/renew/socialSecurityRenew'
    },
    {
        name: <div className='item_block_font'><p>薪酬代发</p></div>,
        icon: <Image src={IMGS.xinchou} width={65} height={65} />,
        page: ''
    }
];

// const openid = getCookies('openid');
// const nickname = getCookies('nickname')
// const headimgurl = getCookies('headimgurl')

const userInfo = sessionStorage.getItem('userInfo')

const { headImgUrl, nickName, userId } = userInfo ? JSON.parse(userInfo) : {}


function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const goPage = (page) => {
        if(page) {
            navigate(page);
            return;
        }
        Toast.show({
            content: '敬请期待',
            duration: 3000
        });
    };

    const times = sessionStorage.getItem('times');

    useEffect(() => {
        if(nickName && headImgUrl && userId) {
            setLoading(false);
            dispatch(saveUser({
                id: userId,
                nickName: nickName,
                headImgUrl: headImgUrl
            }));
            return;
        } else if(isWx() && !times) {
            fetchWxCode()
        }
    }, []);

    const fetchWxCode = () => {
        try {
            setLoading(true);
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=${SCOPE}&state=STATE#wechat_redirect`;
            // window.location.href = ('http://localhost:3000?a=1&code=1234');
            sessionStorage.setItem('times', 1);
        } catch(error) {
            console.log('error-', error);
        }
    }

    useEffect(() => {
        if(nickName && headImgUrl && userId) {
            return;
        }
        let code = getUrlParms(location.href, 'code');
        if(code) {
            localStorage.setItem('code', code )
            get('weixin/invoke', {code: code}).then((res) => {
                if (res.code === 200 && res.data){
                    setLoading(false);
                    sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                    dispatch(saveUser({
                        id: res.data.userId,
                        nickName: res.data.nickName,
                        headImgUrl: res.data.headImgUrl
                    }));
                } else{
                    setLoading(false);
                    sessionStorage.removeItem('times');
                    isWx() && fetchWxCode()
                }
            }).catch(() => {
                sessionStorage.removeItem('times');
                isWx() && fetchWxCode()
            });
        } else {
            sessionStorage.removeItem('times');
            isWx() && fetchWxCode()
        }
    },[window.location.href]);

    if(loading) {
        return (
            <DotLoading color='primary' style={{ display:'flex', justifyContent: 'center', paddingTop: '200px'}} />
        )
    }
    
    return <div className="App">
        <Header />
        <div style={{paddingBottom: '80px'}}>
            <Card bodyClassName='part_1'>
                {
                    iconInfoList.map((ele, idx) => <div className='item_block' key={idx} onClick={() => {goPage(ele.page);}}>
                        {ele.icon}
                        {ele.name}
                    </div>)
                }
            </Card>
            {/* 公告 */}
            <Notice />
            {/* 政策解读 */}
            <Policy />
            {/* 指南icon */}
            <Tour />
            {/* 备案号 */}
            <Contact />
        </div>
        <Footer />
    </div>
    ;
}

export default App;