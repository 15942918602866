// 补缴社保
import React, { useState } from 'react';
import { Header } from '../component/header';
import { Notice } from '../component/notice';
import { CustomeCard } from '../component/custome-card';

import { Collapse, Toast, Image } from 'antd-mobile';

import { useLocation, useNavigate } from 'react-router-dom';
import {get} from '../common/fetch';


import './style.scss';

const IMGS = {
    loadingImage: require('../images/loader.gif'),
};

export const SocialSecurityRenewConfirm  = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { data, formatData } = state || {};
    const [showLoading, setShowLoading] = useState(false)

    const socialList = data?.orderDetailVos.filter(item => item.socialType === 51);
    const housingList = data?.orderDetailVos.filter(item => item.socialType === 52);
    const taxList = data?.orderDetailVos.filter(item => item.socialType === 53);


    const onFinish = () => {
        window.scrollTo({top: 0})
        setShowLoading(true);
        get('order/submit', formatData).then((res) => {
            if (res.code === 200 && res.data){
                // TODO: 该接口目前报错，暂不联调
                // {
                //     appId: '',
                //     timeStamp: '',
                //     nonceStr: '',
                //     package: '',
                //     paySign: ''
                // }
                console.log('提交接口----,', res, res.data)
                onBridgeReady(res.data);
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '提单失败',
                });
            }
        }).finally(() => {
            setShowLoading(false);
        });
    };

    const onBridgeReady = (data) => {
        setShowLoading(false);
        console.log('onBridgeReady方法data-------, data', data);
        let newData = {...data};
        newData.package = data.packageVal;
        console.log('onBridgeReady方法data-------, newData', newData);
        window.WeixinJSBridge && window.WeixinJSBridge.invoke('getBrandWCPayRequest',{...newData},
            function(res) {
                console.log('进入获取微信支付方法-----',res)
                if (res.err_msg == 'get_brand_wcpay_request:ok') {
                    Toast.show({
                        icon: 'success',
                        content: '支付成功',
                    });
                    sessionStorage.removeItem('times');
                    // window.location.href='/'
                    // 跳转支付成功页
                    // navigate('/paySuccessPage', { state: {price: data?.orderTotalFee / 100  ?? ''}});
                    navigate('/');

                } else {
                    console.log('微信支付失败-----',res)
                    Toast.show({
                        icon: 'fail',
                        content: '支付失敗',
                    });
                }
            });
        if (typeof WeixinJSBridge == 'undefined') {
            console.log('WeixinJSBridge为undefind进入这里')
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
        } else {
            console.log('进入else')
            // onBridgeReady();
        }
    };

    if(!data || !Object.keys(data)?.length) {
        return null;
    }

    return (
        <>
            <div style={{paddingBottom: '80px'}}>
                <Header />
                {/* 公告 */}
                <Notice />
                
                {/* 补缴社保卡片 */}
                <div className='marginT'>
                    <CustomeCard  title='续费基础信息'>
                        <div className='confirm_info_item'>
                            <p>参保姓名</p>
                            <p>{data?.fullName ?? ''}</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>续缴城市/区县</p>
                            <p>北京市</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>社保基数</p>
                            <p>{data?.secuBaseValue / 100 ?? ''}</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>公积金基数</p>
                            <p>{data?.housingBaseFee / 100 ?? ''}</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>缴存期限</p>
                            <p>{data?.startDate ?? ''}至{data?.endDate ?? ''}</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>缴存月份数</p>
                            <p>{data?.monthSum ?? ''}个月</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>缴存项目</p>
                            <p className='red_color'>{data?.productName ?? ''}</p>
                        </div>
                    </CustomeCard>
                </div>
                
                <div className='marginT'>
                    <CustomeCard  title='续费费用明细'>
                        <Collapse defaultActiveKey={['1']}>
                            <Collapse.Panel key='1' title={
                                <div className='detail_title'>
                                    <span>社保费（{data?.monthSum ?? ''}个月）</span>
                                    <span>￥{data?.socialSumFee / 100 ?? ''}</span>
                                </div>
                            }>
                                <div className='detail_cont'>
                                    {
                                        !!socialList.length && socialList.map((item, index) => {
                                            return (
                                                <div key={index} className='detail_cont_item'>
                                                    <span className='month_layer'>{item.socialMonth}，</span>
                                                    <span>社保费：￥{item?.socialSecuFee / 100  ?? ''}</span>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel key='2' title={
                                <div className='detail_title'>
                                    <span>公积金（比例各占5%）（{data?.monthSum ?? ''}个月）</span>
                                    <span>￥{data?.housingSumFee / 100  ?? ''}</span>
                                </div>
                            }>
                                <div className='detail_cont'>
                                    {
                                        !!housingList.length && housingList.map((item, index) => {
                                            return (
                                                <div key={index} className='detail_cont_item'>
                                                    <span className='month_layer'>{item.socialMonth}，</span>
                                                    <span>公积金：￥{item?.socialSecuFee / 100  ?? ''}</span>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Collapse.Panel>
                            {
                                !!taxList.length && 
                                <Collapse.Panel key='3' title={
                                    <div className='detail_title'>
                                        <span>个税税额（{data?.monthSum ?? ''}个月）</span>
                                        <span>￥{data?.personalTaxSumFee / 100  ?? ''}</span>
                                    </div>
                                }>
                                    <div className='detail_cont'>
                                        {
                                            !!taxList.length && taxList.map((item, index) => {
                                                return (
                                                    <div key={index} className='detail_cont_item'>
                                                        <span className='month_layer'>{item?.socialMonth  ?? ''}，</span>
                                                        <span>税额：￥{item?.socialSecuFee / 100  ?? ''}</span>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </Collapse.Panel>
                            }
                            <Collapse.Panel key='4' title={
                                <div className='detail_title'>
                                    <span>服务费（{data?.monthSum ?? ''}个月）</span>
                                    <span>￥{data?.serviceSumFee / 100  ?? ''}</span>
                                </div>
                            }>
                                <div className='detail_cont'>
                                    { 
                                        socialList.map((item, index)=> {
                                            return (
                                                <div key={index}>
                                                    {
                                                        !!socialList.length && <div className='detail_cont_item'>
                                                            <span className='month_layer'>{item.socialMonth}，</span>
                                                            <span>社保服务费：￥{item?.missionFee / 100  ?? ''}</span>
                                                        </div>
                                                    }
                                                    {
                                                        !!housingList.length && <div className='detail_cont_item'>
                                                            <span className='month_layer'>{item.socialMonth}，</span>
                                                            <span>公积金服务费：￥{item?.missionFee / 100  ?? ''}</span>
                                                        </div>
                                                    }
                                                    {
                                                        !!taxList.length && <div className='detail_cont_item'>
                                                            <span className='month_layer'>{item.socialMonth}，</span>
                                                            <span>个税服务费+残保金：￥{taxList[index]?.missionFee / 100  ?? ''}</span>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                        
                        {/* <div className='detail_title wx_line' style={{ borderBottom: 'solid 1x #eee'}}>
                            <span>服务费（1个月）</span>
                            <span>￥{data?.serviceSumFee / 100  ?? ''}</span>
                        </div> */}
                        <div className='detail_title wx_line'>
                            <span>微信支付通道费（0.6%）</span>
                            <span>￥{data?.wxPayFee / 100  ?? ''}</span>
                        </div>
                    </CustomeCard>

                </div>
                <div className='marginT'>
                    <CustomeCard  title='续缴费用总计'>
                        <div className='confirm_info_item'>
                            <h4>费用总计</h4>
                            <p>￥{data?.orderTotalFee / 100  ?? ''}元</p>
                        </div>
                    </CustomeCard>
                </div>
                <div className='pay_box'>
                    <div className='amount'>实付款：<span className='red_color'>￥{data?.orderTotalFee / 100  ?? ''}</span></div>
                    <div className='pay_button' onClick={onFinish}>立即支付</div>
                </div>
            </div>
            {/* loading */}
            {
                showLoading && <div className='loading_box'>
                    <div className='loading_img_box'>
                        <Image src={IMGS.loadingImage} width={40} height={40} fit='cover' />     
                    </div>
                </div>
            }
        </>
        
    );
};
