import React from 'react';
// import PropTypes from 'prop-types';

import './style.scss';
import { Card } from 'antd-mobile';
import { CompassOutline, ContentOutline, CheckShieldOutline } from 'antd-mobile-icons';
import { useNavigate  } from 'react-router-dom';


export const Tour = () => {
    const navigate = useNavigate();

    const goXf = () => {
        navigate('/detail');
    }

    const goDetail = () => {
        navigate('/bujiaoDetail');
    }

    const about = () => {
        navigate('/aboutUs');
    }

    return (
        <Card bodyClassName='part_1'>
            <div className='item_block' onClick={about}>
                <CheckShieldOutline  color='#76c6b8' fontSize={36} />
                <div className='item_block_font'>关于平台</div>
            </div>
            <div className='item_block' onClick={goXf}>
                <CompassOutline  color='#76c6b8' fontSize={36} />
                <div className='item_block_font'>续费说明</div>
            </div>
            <div className='item_block' onClick={goDetail}>
                <ContentOutline  color='#76c6b8' fontSize={36} />
                <div className='item_block_font'>补缴说明</div>
            </div>
            
        </Card>
    );
};


