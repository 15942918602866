import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import './index.css';
import App from './App';
import ErrorPage from './404';
// 补缴相关页面
import { SupplementaryPaymentHomePage, SocialSecurity, AccumulationFund, SocialSecurityConfirm, AccumulationFundConfirm } from './supplementary-payment';
import { MineHomePage } from './mine';
import { InsuredPersonnel, AddPerson} from './insured-personnel';
import { PaymentList } from './payment-list';
// 续费相关页面
import { SocialSecurityRenew, SocialSecurityRenewConfirm  } from './renew';
import store from './redux/store';
import { Provider } from 'react-redux';

import { NoitcePage } from './notice';
import { PaySuccessPage } from './paySuccess';
import { HousePage } from './staticPage/house';
import { DomicilePage } from './staticPage/domicile';
import { CarPage } from './staticPage/car';
import { SchoolPage } from './staticPage/school';
import { AboutUs } from './staticPage/aboutUs';
import { Detail } from './staticPage/detail';
import { BujiaoDetail } from './staticPage/bujiao'

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />
    },
    {
        path: '/renew',
        element: <App />,
        errorElement: <ErrorPage />
    },
    {
        path: '/supplementaryPaymentHomePage',
        element: <SupplementaryPaymentHomePage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/supplementaryPaymentHomePage/socialSecurity',
        element: <SocialSecurity />,
        errorElement: <ErrorPage />
    },
    {
        path: '/supplementaryPaymentHomePage/socialSecurityConfirm',
        element: <SocialSecurityConfirm />,
        errorElement: <ErrorPage />
    },
    {
        path: '/supplementaryPaymentHomePage/accumulationFund',
        element: <AccumulationFund />,
        errorElement: <ErrorPage />
    },
    {
        path: '/supplementaryPaymentHomePage/accumulationFundConfirm',
        element: <AccumulationFundConfirm />,
        errorElement: <ErrorPage />
    },
    {
        path: '/mine',
        element: <MineHomePage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/insuredPersonnel',
        element: <InsuredPersonnel />,
        errorElement: <ErrorPage />
    },
    {
        path: '/AddPerson',
        element: <AddPerson />,
        errorElement: <ErrorPage />
    },
    {
        path: '/paymentList',
        element: <PaymentList />,
        errorElement: <ErrorPage />
    },
    {
        path: 'renew/socialSecurityRenew',
        element: <SocialSecurityRenew />,
        errorElement: <ErrorPage />
    },
    {
        path: 'renew/socialSecurityRenewConfirm',
        element: <SocialSecurityRenewConfirm />,
        errorElement: <ErrorPage />
    },
    {
        path: '/notice',
        element: <NoitcePage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/paySuccessPage',
        element: <PaySuccessPage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/house',
        element: <HousePage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/domicile',
        element: <DomicilePage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/car',
        element: <CarPage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/school',
        element: <SchoolPage />,
        errorElement: <ErrorPage />
    },
    {
        path: '/aboutUs',
        element: <AboutUs />,
        errorElement: <ErrorPage />
    },
    {
        path: '/detail',
        element: <Detail />,
        errorElement: <ErrorPage />
    },
    {
        path: '/bujiaoDetail',
        element: <BujiaoDetail />,
        errorElement: <ErrorPage />
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}  />
        </Provider>
    </React.StrictMode>
);


