import React, { useState, useEffect } from 'react';

import { Footer } from '../component/footer';
import { Header } from '../component/header';
import { Notice } from '../component/notice';
import { CustomeCard } from '../component/custome-card';
import { PersonList } from '../component/pick-person';

import { Button } from 'antd-mobile';
import { useNavigate  } from 'react-router-dom';
import {get} from '../common/fetch';
import { domicileEnum, socilStatus} from '../common/utils';
import { useSelector } from 'react-redux';

import './style.scss';

// const personListData = [
//     {
//         name: '张三',
//         hj: '外埠-城镇',
//         quxian: '北京',
//         idCard: '110101201812100022'
//     },
//     {
//         name: '李四',
//         hj: '外埠-城镇',
//         quxian: '北京',
//         idCard: '130101201812100011'
//     }
// ];

export const InsuredPersonnel = () => {
    const navigate = useNavigate();
    const { USER_ID } = useSelector(state=>state.userInfo);

    const [personListData, setPersonListData] = useState([]);

    useEffect(() => {
        get('person/list', {userId: USER_ID}).then((res) => {
            if (res.code === 200 && res.data){
                setPersonListData(res.data);
            }
        });
    }, []);

    const addPerson = () => {
        navigate('/AddPerson', { state: {isEdit: false}});
    };

    const editPerson = (item) => {
        navigate('/AddPerson', { state: {isEdit: true, personInfo: item}});
    }


    return (
        <>
            <Header />
            {/* 公告 */}
            <div className='marginT'>
                <Notice />
            </div>
            <div className='marginT' style={{paddingBottom: '80px'}}>
                <CustomeCard title='我的参保人（减员状态为本系统中的状态）'>
                    <>
                        {
                            personListData.map((item, index) => {
                                return (
                                    <div key={index} className='person_item'>
                                        <div className='person_item_info'>
                                            <div>
                                                <p>{index + 1}、{item.fullName}</p>
                                                <p>{domicileEnum.get(item.birthType)}</p>
                                            </div>
                                            <div>
                                                <p>{item.idCardNumber}</p>
                                                <p>社保{socilStatus.get(item.socialSecuStatus)}/公积金{socilStatus.get(item.socialSecuStatus)}</p>
                                            </div>
                                        </div>
                                        <div className='btn_wrap'>
                                            <Button color='primary' size='small' fill='outline'>正常缴费</Button>
                                            <Button color='primary' size='small' fill='outline'>补社保</Button>
                                            <Button color='primary' size='small' fill='outline'>补公积金</Button>
                                            <Button color='primary' size='small' fill='outline' onClick={()=>editPerson(item)}>编辑</Button>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </>
                  
                </CustomeCard>
                <Button color='success' onClick={addPerson} className='add_person_button'>新增参保人员</Button>
            </div>
            
            <Footer />

            {false && <PersonList />}
        </>
    );
};