import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { RightOutline } from 'antd-mobile-icons';
import { CustomePicker } from '../custome-picker';

import './style.scss';

export const SelectRightArrow = (props) => {
    const { defaultText='请选择', value='',title='', basicColumns=[], onChange,  type, handleReloadPerson} = props;

    const [visible, setVisible] = useState(false);
    const [txt, setTxt] = useState('');

    const onClickHandler = () => {
        setVisible(true);
    };
    const onConfirmHandler = (value, extend) => {
        setTxt(extend?.items[0]?.label);
        onChange?.(value);
    };

    useEffect(() => {
        let getInfoArr = !!basicColumns.length && !!basicColumns[0].length && basicColumns[0].filter(i => {
            return i.value === value[0]
        })
        
        setTxt(getInfoArr?.[0]?.label || '');
    }, [value, basicColumns, basicColumns.length])

    return (
        <div className='text_select_box' onClick={onClickHandler}>
            {
                value ? <div>{txt}</div>:<div className='red_color'>{defaultText}</div>
            }
            <RightOutline />
            <CustomePicker
                title={title}
                basicColumns={basicColumns}
                visible={visible}
                setVisible={() => {
                    setVisible(false);
                }}
                value={value}
                onClose={() => {
                    setVisible(false);
                }}
                onConfirm={onConfirmHandler}
                type={type}
                reloadPerson={handleReloadPerson}
            />
        </div>
    );
};

SelectRightArrow.propTypes = {
    defaultText: PropTypes.string,
    value: PropTypes.string,
    title: PropTypes.string,
    basicColumns: PropTypes.array,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    type: PropTypes.string,
    handleReloadPerson: PropTypes.func
};
