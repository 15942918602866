import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Avatar, Image, Mask } from 'antd-mobile';
import { useSelector } from 'react-redux';
import './style.scss';

const IMGS = {
    service: require('../../images/service.png'),
    qingwaLogo: require('../../images/hicetea24.png'),
    weixin: require('../../images/weixin.png'),
    phone: require('../../images/phone.png'),
    phoneNew: require('../../images/headphones(1).png')
};

export const Header = () => {
    const [visible, setVisible] = useState(false);
    const { nickName, headImgUrl } = useSelector(state=>state.userInfo);
    const onClick = () => {
        setVisible(true);
        // Dialog.alert({
        //     // TODO: 弹窗内容还需要完善
        //     content: '点击遮罩关闭',
        //     closeOnMaskClick: true,
        // });
    };

    return (
        <>
            <div style={{ positon: 'relative'}}>
                <div className='headerBox'>
                    <div className='headerBot_left'>
                        <div className='qw_logo_box_new'>
                            <Image
                                src={IMGS.qingwaLogo}
                                width={45}
                                height={30} />
                            <span className='logo_text'>青蛙社保</span>
                        </div>
                        <div className='phoneBox'>
                            <div className='photo'><Avatar style={{ '--size': '50px', '--border-radius': '25px' }} src={headImgUrl} /></div>
                            <p className='name'>{nickName || '请登录'}</p>
                        </div>
                    </div>
                    <div className='info'>
                        <Image src={IMGS.service}
                            width={32}
                            height={32}
                            onClick={onClick}
                        />
                    </div>
                </div>
                
                {/* <div className='headerBox'>
                    <div className='phoneBox'>
                        <div className='photo'><Avatar style={{ '--size': '50px', '--border-radius': '25px' }} src={headImgUrl} /></div>
                        <p className='name'>{nickName || '请登录'}</p>
                    </div>
                    <div className='info'>
                        <Image src={IMGS.service}
                            width={32}
                            height={32}
                            onClick={onClick}
                        />
                    </div>
                </div>
                <div className='qw_logo_box'>
                    <Image
                        src={IMGS.qingwaLogo}
                        width={45}
                        height={30} />
                    <span className='logo_text'>青蛙社保</span>
                </div> */}
            </div>
            {/* 客服弹窗 */}
            <Mask visible={visible} onMaskClick={() => setVisible(false)}>
                <div className='kefu_box'>
                    <div className='kefu_logo_box'>
                        <div className='logo'>
                            <Image src={IMGS.qingwaLogo}
                                width={32}
                                height={27}
                                onClick={onClick}
                            />
                        </div>
                        <div>青蛙社保专属客服</div>
                    </div>
                    <div className='kefu_box_content'>
                        <div className='kefu_box_content_left'>
                            <Image src={IMGS.weixin}
                                width={60}
                                height={60}
                                onClick={onClick}
                            />
                            <p>加微信咨询</p>
                            <p className='light_grey_color'>08:00-23:00</p>
                        </div>
                        <div className='kefu_box_content_right'>
                            <p>18500284876</p>
                            <div className='phone_line' onClick={()=>{window.location.href='tel:18500284876'}}>
                                <Image src={IMGS.phone}
                                    width={32}
                                    height={27}
                                    onClick={onClick}
                                />
                                <span className='red_color'>拨打</span>
                            </div>
                            <p>电话客服</p>
                            <p className='light_grey_color'>08:00-21:30</p>
                        </div>
                    </div>
                </div>
            </Mask>
        </>
    );
};

// Header.propTypes = {
// };


