import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Popup, Button, Image, CenterPopup, Form, Input, Toast } from 'antd-mobile';
import { SelectRightArrow } from '../../component/select-right-arrow'; 

import './style.scss';
import { styled } from './addperosn-style';
import {get} from '../../common/fetch';
import { useSelector } from 'react-redux';

const IMGS = {
    noPerson: require('../../images/no_person.png')
};

export const AddPerson = (props) => {
    const { visible, onClose, reloadPerson } = props;
    const [showAddPerson, setShowAddPerson] = useState(false)
    const [idTypeList, setIdTypeList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [form] = Form.useForm();
    const { USER_ID } = useSelector(state=>state.userInfo);
    
    const onShowAddPerson = () => {
        setShowAddPerson(true)
        onClose()
    }

    const onCloseCenterPop = () => {
        setShowAddPerson(false)
    }

    useEffect(() => {
        get('person/birthType').then((res) => {
            if (res.code === 200 && res.data){
                const formatArr = res?.data?.length && res.data.map((ele) => {
                    return {
                        label: ele.birthTypeName,
                        value: ele.birthTypeId
                    };
                });
                setIdTypeList([formatArr]);
            }
        });
        get('person/birthPlace').then((res) => {
            if (res.code === 200 && res.data){
                const formatArr = res?.data?.length && res.data.map((ele) => {
                    return {
                        label: ele.birthName,
                        value: ele.birthId
                    };
                });
                setProvinceList([formatArr]);
            }
        });
    }, []);

    const onFinish = () => {
        const values = form.getFieldsValue();

        values.type = values.type?.[0];
        values.provinceId = values.provinceId?.[0];
        values.userId = USER_ID;

        get('person/add', values).then((res) => {
            if (res.code === 200 ){
                reloadPerson && reloadPerson()
                Toast.show({
                    icon: 'success',
                    content: '保存成功',
                });
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '保存失败',
                });
            }
        });
    };


    return (
        <>
            <Popup
                visible={visible}
                onMaskClick={onClose}
                onClose={onClose}
                bodyStyle={styled.addPerosnBox}
            >
                <Image src={IMGS.noPerson}
                    width={182}
                    height={130}
                />
                <Button color='success' style={{width: '80%'}} onClick={onShowAddPerson}>添加参保人信息</Button>
            </Popup>


            <CenterPopup
                visible={showAddPerson}
                onMaskClick={onCloseCenterPop}
                onClose={onCloseCenterPop}
                style={{ '--max-width': '80vw'}}
            >
                <div className='add_box'>
                    <div className='add_title'>请填写参保人信息</div>
                    <Form
                        form={form}
                        className='addPersonForm'
                    >
                        <Form.Item
                            name='name'
                            label='本人姓名'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                        >
                            <Input
                                placeholder='请输入完整姓名'
                                style={{ '--text-align': 'right' }} />
                        </Form.Item>
                        <Form.Item
                            name='idCard'
                            label='身份证号'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '40%'}}
                            layout='horizontal'
                        >
                            <Input
                                placeholder='请输入身份证号'
                                style={{ '--text-align': 'right' }} />
                        </Form.Item>
                        <Form.Item
                            name='type'
                            label='户口类型'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                        >
                            <SelectRightArrow 
                                title={'户口类型'}
                                basicColumns={idTypeList}
                                
                            />
                        </Form.Item>
                        <Form.Item
                            name='provinceId'
                            label='户籍省份'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                        >
                            <SelectRightArrow 
                                title={'户籍所在地'}
                                basicColumns={provinceList}
                            
                            />
                        </Form.Item>
                        <Form.Item
                            name='mobile'
                            label='手机号码'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '40%'}}
                            layout='horizontal'
                        >
                            <Input
                                placeholder='请输入手机号码'
                                style={{ '--text-align': 'right' }} />
                        </Form.Item>
                       
                    </Form>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',paddingBottom: '20px', paddingTop: '20px'}}>
                    <Button color='success' style={{width: '80%'}} onClick={onFinish}>保存</Button>
                </div>
            </CenterPopup>
        </>
       
    );
};

AddPerson.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    reloadPerson: PropTypes.func
};