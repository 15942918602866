import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, Space, Button, Input  } from 'antd-mobile';

import { SelectRightArrow } from '../component/select-right-arrow';

import { getPreMonthList } from '../common/utils';
import { useSelector } from 'react-redux';
import { AddOutline } from 'antd-mobile-icons'

import {get} from '../common/fetch';

import { useNavigate  } from 'react-router-dom';


export const CardForm = (props) => {
    const { USER_ID } = useSelector(state=>state.userInfo);
    const { onFinish, choosePerson, type } = props;
    const [form] = Form.useForm();
    const [personListData, setPersonListData] = useState([]);
    const [shebaoMin, setShebaoMin] = useState(0);
    const [gjjMin, setGjjMin] = useState(0);
    const [shebaoMax, setShebaoMax] = useState(0);
    const [gjjMax, setGjjMax] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        loadPerson();

        get('base/list', {cityId: 1100}).then((res) => {
            if (res.code === 200 && res.data){
                res.data.forEach((ele) => {
                    if(ele.socialType === 51){
                        setShebaoMin(ele.baseMinValue / 100);
                        setShebaoMax(ele.baseMaxValue / 100);
                        form.setFieldValue('secuBaseValue', ele.baseMinValue / 100);
                    } else {
                        setGjjMin(ele.baseMinValue / 100);
                        setGjjMax(ele.baseMaxValue / 100);
                        form.setFieldValue('housingBaseFee', ele.baseMinValue / 100);
                    }
                });
            }
        });
    }, []);

    const loadPerson = () => {
        get('person/list', {userId: USER_ID}).then((res) => {
            if (res.code === 200 && res.data){
                const formatArr = res.data.map((ele) => {
                    return {
                        label: ele.fullName,
                        value: ele.birthType+'_'+ele.id
                    };
                });
                setPersonListData([formatArr]);
            }
        });
    }

    const handleOnFinish = () => {
        const values = form.getFieldsValue();
        onFinish(values);
    };

    const goAdd = () => {
        navigate('/AddPerson', { state: {isEdit: false}});
    }

    return (
        <>
            <Form
                form={form}
                // onFinish={handleOnFinish}
                
            >
                <Form.Item
                    initialValue={1100}
                    name='cityId'
                    label='补缴城市(仅支持北京)'
                    rules={[{ required: true }]}
                    style={{'--prefix-width': '60%'}}
                    layout='horizontal'
                >
                    <div>北京市</div>
                </Form.Item>
                <Form.Item
                    name='secuPersonId'
                    label={<span>选择补缴人&nbsp;&nbsp;<span style={{ color: '#1677ff'}} onClick={goAdd}>(<AddOutline />添加)</span></span>}
                    rules={[{ required: true }]}
                    style={{'--prefix-width': '60%'}}
                    layout='horizontal'
                >
                    <SelectRightArrow defaultText={'请先选择参保人'} onChange={choosePerson} basicColumns={personListData}  type='person' handleReloadPerson={loadPerson}/>
                                
                </Form.Item>

                {
                    type === 'shebao'
                        ? <Form.Item
                            initialValue={5100}
                            name='item'
                            label='补缴项目(社保)'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                        >
                            <Checkbox checked><span style={{fontSize: '14px'}}>{type ==='shebao' ? '社保' : '公积金'}</span></Checkbox>
                        </Form.Item>
                        : <Form.Item
                            initialValue={5200}
                            name='item'
                            label='补缴项目(社保)'
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                        >
                            <Checkbox checked><span style={{fontSize: '14px'}}>{type ==='shebao' ? '社保' : '公积金'}</span></Checkbox>
                        </Form.Item>
                }
                {
                    type === 'shebao' 
                        ? <Form.Item
                            name='secuBaseValue'
                            label={`社保基数(${shebaoMin}-${shebaoMax})`}
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                            extra={
                                <div>
                                  元
                                </div>
                            }

                        >
                            <Input
                                type='number'
                                min={shebaoMin}
                                max={shebaoMax}
                                placeholder='请输入内容'
                                style={{ '--text-align': 'right' }} />
                        </Form.Item>
                        : <Form.Item
                            name='housingBaseFee'
                            label={`公积金基数(${gjjMin}-${gjjMax})`}
                            rules={[{ required: true }]}
                            style={{'--prefix-width': '60%'}}
                            layout='horizontal'
                            extra={
                                <div>
                                  元
                                </div>
                            }

                        >
                            <Input
                                type='number'
                                min={gjjMin}
                                max={gjjMax}
                                placeholder='请输入内容'
                                style={{ '--text-align': 'right' }} />
                        </Form.Item>
                }
                <Form.Item
                    name='month'
                    label={`补缴月份(补缴${getPreMonthList(3)[2]}之前的，请咨询客服)`}
                    rules={[{ required: true }]}
                    style={{'--prefix-width': '100%'}}
                    layout='vertical'
                >
                    <Checkbox.Group>
                        <Space wrap>
                            {
                                getPreMonthList(3).length > 0 && getPreMonthList(3).map((month) => {
                                    return  <Checkbox value={month} key={month}>{month}</Checkbox>;
                                })
                            }
                            {/* <Checkbox value='2023-02'>2023-02</Checkbox>
                            <Checkbox value='2023-03'>2023-03</Checkbox>
                            <Checkbox value='2023-04'>2023-04</Checkbox>
                            <Checkbox value='2023-05'>2023-04</Checkbox>
                            <Checkbox value='2023-06'>2023-04</Checkbox> */}
                        </Space>
                    </Checkbox.Group>
                </Form.Item>
            </Form>
            <Button color='success' onClick={handleOnFinish} style={{ marginTop: '20px'}}>下一步，计算费用</Button>
        </>
    );
};


CardForm.propTypes = {
    onFinish: PropTypes.func,
    choosePerson: PropTypes.func,
    type: PropTypes.string
};
