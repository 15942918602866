import React from 'react';
// import PropTypes from 'prop-types';

import './style.scss';

import { useNavigate, useHref  } from 'react-router-dom';

import { ReceivePaymentOutline, HandPayCircleOutline, UnorderedListOutline, UserContactOutline } from 'antd-mobile-icons';

const footerList = [
    {
        name: '续费',
        page: 'renew',
        icon: <ReceivePaymentOutline fontSize={24} />
    },
    {
        name: '补缴',
        page: 'supplementaryPaymentHomePage',
        icon: <HandPayCircleOutline fontSize={24}/>
    },
    {
        name: '缴费记录',
        page: 'paymentList',
        icon: <UnorderedListOutline fontSize={24}/>
    },
    {
        name: '我的',
        page: 'mine',
        icon: <UserContactOutline fontSize={24}/>
    },
];

export const Footer = () => {

    const navigate = useNavigate();
    const href = useHref();

    const gotoPage = (page) => {
        navigate(`/${page}`);
    };

    return (
        <div className='footerBox'>
            {
                footerList.map((item, index) => {
                    return (
                        <div key={item.name} className={`footerItem ${(href.includes(item.page) || (href === '/' && index===0) ) && 'activeItem'}` } onClick={()=>gotoPage(item.page)}>
                            {/* <i>icon</i> */}
                            {item.icon}
                            <p>{item.name}</p>
                        </div>
                    );
                })
            }
        </div>
    );
};

Footer.propTypes = {
    // activePage: PropTypes.string
};


