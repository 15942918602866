// 续缴社保
import React from 'react';
import { Image } from 'antd-mobile';
import './style.scss';

const IMGS = {
    car: require('../images/car.png'),
};

export const CarPage = () => {
    return (
        <div className='policy_page_box'>
            <div className='big_title'>买车政策</div>
            <Image src={IMGS.car} width={190} className='policy_img'/>,
            <div className='policy_con'>
                <p className='policy_title'>非京籍户口在京购车摇号:</p>
                <p>连续5年(含)以上在京缴纳社保和个人所得税(每年至少一笔金额大于0的个税缴税记录)。社保不能断月，如有断月，补缴有效。</p>
            </div>
        </div>
    )
}