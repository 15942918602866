export const styled = {
    addPerosnBox: {
        height: '220px',
        background: '#f2f2f2',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop:'20px'
    }
};