// 续缴社保
import React from 'react';
import { Contact } from '../component/contacts';
import './style.scss';


export const AboutUs = () => {
    return (
        <div className='policy_page_box'>
            <div className='big_title'>关于我们</div>
            <div className='policy_con'>
                <p>一、服务人群:</p>
                <p className='suojin'>企业客户、自由职业者等；</p>
                <p>二、社保公积金续缴，补缴平台:</p>
                <p className='suojin'>提供北京社保续缴，社保补缴业务，养老，失业，工伤，医疗，生育保险解决您北京积分落户，摇车牌，在京购房，在京看病，子女在京入学等资格问题;</p>
                <p>三、线上操作，方便快捷</p>
                <p className='suojin'>通过微服务平台，线上办理相关的业务，灵活、方便、快捷的一站式服务平台，解决您的后顾之忧。</p>
                <p style={{textAlign: 'right'}}>青蛙保平台</p>
            </div>
            {/* 备案号 */}
            <div style={{ paddingTop: '30px'}}>
                <Contact />
            </div>
        </div>
    )
}