import React, { useState, useEffect } from 'react';

import { CustomeCard } from '../component/custome-card';
import { Form, Input, Button, Toast } from 'antd-mobile';
import { SelectRightArrow } from '../component/select-right-arrow';
import {get} from '../common/fetch';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import './style.scss';



export const AddPerson = () => {
    const [form] = Form.useForm();
    const { USER_ID } = useSelector(state=>state.userInfo);


    const [idTypeList, setIdTypeList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);

    const { state } = useLocation();
    const { isEdit, personInfo } = state || {};

    console.log('isEdit---', isEdit)
    console.log('personInfo---', personInfo)


    useEffect(() => {
        get('person/birthType').then((res) => {
            if (res.code === 200 && res.data){
                const formatArr = res.data.map((ele) => {
                    return {
                        label: ele.birthTypeName,
                        value: ele.birthTypeId
                    };
                });
                setIdTypeList([formatArr]);
            }
        });
        get('person/birthPlace').then((res) => {
            if (res.code === 200 && res.data){
                const formatArr = res.data.map((ele) => {
                    return {
                        label: ele.birthName,
                        value: ele.birthId
                    };
                });
                setProvinceList([formatArr]);
            }
        });

       
        
    }, []);

    useEffect(()=>{
        if(isEdit) {
            // form.setFieldValue('name', personInfo.fullName);
            form.setFieldsValue({
                'name': personInfo.fullName,
                'idCard': personInfo.idCardNumber,
                'type': [personInfo.birthType],
                'provinceId': [personInfo.birthProvinceId],
                'mobile': personInfo.mobile
            })
        }
    },[isEdit, personInfo])



    const onFinish = async() => {
        try {
            // const values = form.getFieldsValue();
            const values = await form.validateFields();
            console.log('values---', values)

            values.type = values.type?.[0];
            values.provinceId = values.provinceId?.[0];
            values.userId = USER_ID;

            if(isEdit) {
                values.id= personInfo.id;
                get('person/update', values).then((res) => {
                    if (res.code === 200 ){
                        Toast.show({
                            icon: 'success',
                            content: '保存成功',
                        });
                        setTimeout(() => {
                            window.history.back(-1);
                        }, 2100)
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.message || '保存失败',
                        });
                    }
                }).catch((err)=>{
                    Toast.show({
                        icon: 'fail',
                        content: err.message || '保存失败',
                    });
                });


            } else {
                get('person/add', values).then((res) => {
                    if (res.code === 200 ){
                        Toast.show({
                            icon: 'success',
                            content: '保存成功',
                        });
                        setTimeout(() => {
                            window.history.back(-1);
                        }, 2100)
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.message || '保存失败',
                        });
                    }
                }).catch((err)=>{
                    Toast.show({
                        icon: 'fail',
                        content: err.message || '保存失败',
                    });
                });
            }
        } catch(e) {
            console.log('e',e)
        }
        
    };

    const onBack = () => {
        window.history.back(-1);
    }

    return (
        <>
            <CustomeCard  title={isEdit ? '请修改参保人信息' : '请填写参保人信息'}>
                   
                <Form
                    form={form}
                    onFinish={onFinish}
                    className='addPersonForm'
                >
                    <Form.Item
                        name='name'
                        label='本人姓名'
                        rules={[{ required: true, message: '姓名不能为空' }]}
                        style={{'--prefix-width': '60%'}}
                        layout='horizontal'
                    >
                        <Input
                            placeholder='请输入完整姓名'
                            style={{ '--text-align': 'right' }} disabled={personInfo?.socialSecuStatus === 1} />
                    </Form.Item>
                    <Form.Item
                        name='idCard'
                        label='身份证号'
                        rules={[{ required: true, message: '身份证号不能为空' }]}
                        style={{'--prefix-width': '40%'}}
                        layout='horizontal'
                    >
                        <Input
                            placeholder='请输入身份证号'
                            style={{ '--text-align': 'right' }} disabled={personInfo?.socialSecuStatus === 1} />
                    </Form.Item>
                    <Form.Item
                        name='type'
                        label='户口类型'
                        rules={[{ required: true, message: '请选择户口类型' }]}
                        style={{'--prefix-width': '60%'}}
                        layout='horizontal'
                    >
                        <SelectRightArrow 
                            title={'户口类型'}
                            basicColumns={idTypeList}
                            
                        />
                    </Form.Item>
                    <Form.Item
                        name='provinceId'
                        label='户籍省份'
                        rules={[{ required: true, message: '请选择户籍省份' }]}
                        style={{'--prefix-width': '60%'}}
                        layout='horizontal'
                    >
                        <SelectRightArrow 
                            title={'户籍所在地'}
                            basicColumns={provinceList}
                           
                        />
                    </Form.Item>
                    <Form.Item
                        name='mobile'
                        label='手机号码'
                        rules={[{ required: true, message: '手机号码不能为空'}]}
                        style={{'--prefix-width': '40%'}}
                        layout='horizontal'
                    >
                        <Input
                            placeholder='请输入手机号码'
                            style={{ '--text-align': 'right' }} />
                    </Form.Item>
                       
                </Form>
            </CustomeCard>
            <Button color='success' onClick={onFinish} className='button'>保存</Button>
            <Button onClick={onBack} className='button' style={{marginTop: '14px'}}>返回</Button>

  
        </>
       
    );
};

