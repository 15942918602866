import React from 'react';

import { Image } from 'antd-mobile';
import { CustomeCard } from '../custome-card';
import './style.css';
import { useNavigate  } from 'react-router-dom';

const IMGS = {
    estate: require('../../images/real-estate.png'),
    car: require('../../images/car.png'),
    graduation: require('../../images/graduation-cap.png'),
    check: require('../../images/check.png'),
};
const policyLIst = [
    {
        title: '买房政策',
        subTitle: '买房政策早知道',
        image: IMGS.estate,
        url: '/house' // 替换成最后给出的url
    },
    {
        title: '买车政策',
        subTitle: '买车政策需要摇号',
        image: IMGS.car,
        url: '/car' // 替换成最后给出的url
    },
    {
        title: '上学政策',
        subTitle: '孩子上学怎么办',
        image: IMGS.graduation,
        url: '/school' // 替换成最后给出的url
    },
    {
        title: '落户政策',
        subTitle: '积分落户有多远',
        image: IMGS.check,
        url: '/domicile' // 替换成最后给出的url
    }
];

export const Policy = () => {
    const navigate = useNavigate();

    const goPage = (url) => {
        if (!url) {
            return;
        }
        navigate(url);
    };
 
    return (
        <div className='part_2'>
            <CustomeCard title='政策解读'>
                <div className='licence_wrap' >
                    {
                        policyLIst.map((ele, idx) => {
                            return <div className='licence_item' key={idx} onClick={() => goPage(ele.url)}>
                                <div className='licence_txt_wrap'>
                                    <div>{ele.title}</div>
                                    <div>{ele.subTitle}</div>
                                </div>
                                <Image src={ele.image}
                                    width={32}
                                    height={32}
                                />
                            </div>;
                        })
                    }
                </div>
            </CustomeCard>
        </div>
    );
};