// 续缴社保
import React from 'react';
import { Contact } from '../component/contacts';
import { Image } from 'antd-mobile';
import './style.scss';
const IMGS = {
    mingxi: require('../images/shebaomingxi.png'),
};

export const BujiaoDetail = () => {
    return (
        <div className='policy_page_box'>
            <div className='big_title'>社保/公积金-补缴说明</div>
            <div className='policy_con'>
                <div style={{ background: '#f1f7f7', borderRadius: '3px', paddingLeft: '20px', paddingBottom: '20px', marginBottom: '10px'}}>
                    <div className='detail_title' style={{paddingLeft: 0, paddingTop: '15px'}}>北京社保-补缴说明</div>
                    <p>1、补缴期限：近三个月;</p>
                    <p>2、补缴基数：可选择;</p>
                    <p>3、补缴近三个月：300元/每人每月</p>
                    <p>4、补缴三个月前：请咨询客服;</p>
                    <p>5、补缴滞纳金：每天万分之五</p>
                </div>
                <div style={{ background: '#f1f7f7', borderRadius: '3px', paddingLeft: '20px', paddingBottom: '20px'}}>
                    <div className='detail_title' style={{paddingLeft: 0, paddingTop: '15px'}}>北京公积金-补缴说明</div>
                    <p>1、补缴期限：不限;</p>
                    <p>2、补缴基数：2500元-28221元;</p>
                    <p>3、补缴比例：公司5% +个人5%;</p>
                    <p>4、补缴前，需要原公司配合减员;</p>
                    <p>5、补缴服务费，300元/每人每月;</p>
                </div>
            </div>
            
            {/* 备案号 */}
            <div style={{ paddingTop: '30px'}}>
                <Contact />
            </div>
        </div>
    )
}










