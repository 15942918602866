// 续缴社保
import React from 'react';
import { Image } from 'antd-mobile';
import './style.scss';

const IMGS = {
    bag: require('../images/bag.png'),
};

export const SchoolPage = () => {
    return (
        <div className='policy_page_box'>
            <div className='big_title'>上学政策</div>
            <Image src={IMGS.bag} width={220} className='policy_img'/>,
            <div className='policy_con'>
                <p className='policy_title'>非京籍户口在京购车摇号:</p>
                <p>1、社保: 根据片区要求申请月的上一个月往前连续缴纳6个月社保，补缴不算</p>
                <p>2、居住证:根据片区要求持证满6个月，办理居住证社保记录满6个月即可</p>
                <p>3、劳动合同：根据片区要求申请月的上一个月满6个月</p>
                <p>注：具体以学校为准，以上仅供参考</p>
            </div>
        </div>
    )
}