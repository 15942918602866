// 补缴社保
import React, { useState } from 'react';
// import { Footer } from '../component/footer';
import { Header } from '../component/header';
import { Contact } from '../component/contacts';
import { Notice } from '../component/notice';
import { CustomeCard } from '../component/custome-card';

import { Collapse, Toast, Image } from 'antd-mobile';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from '../common/fetch';

import './style.scss';
const IMGS = {
    loadingImage: require('../images/loader.gif'),
};

export const SocialSecurityConfirm  = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { data, formatData } = state || {};
    const [showLoading, setShowLoading] = useState(false);

    const onFinish = () => {
        window.scrollTo({top: 0})
        setShowLoading(true)
        get('order/submit', formatData).then((res) => {
            if (res.code === 200 && res.data){
                console.log('提交接口----,', res, res.data)
                onBridgeReady(res.data);
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '提单失败',
                });
            }
        }).finally(() => {
            setShowLoading(false)
        });
       
    };

    const onBridgeReady = (data) => {
        setShowLoading(false)
        console.log('onBridgeReady方法data-------, data', data);
        let newData = {...data};
        newData.package = data.packageVal;
        console.log('onBridgeReady方法data-------, newData', newData);
        window.WeixinJSBridge && window.WeixinJSBridge.invoke('getBrandWCPayRequest',{...newData},
            function(res) {
                console.log('进入获取微信支付方法-----',res)
                if (res.err_msg == 'get_brand_wcpay_request:ok') {
                    Toast.show({
                        icon: 'success',
                        content: '支付成功',
                    });
                    // window.location.href='/'
                    // 跳转支付成功页
                    // navigate('/paySuccessPage', { state: {price: data?.orderTotalFee / 100  ?? ''}});
                    navigate('/');
                } else {
                    console.log('微信支付失败-----',res)
                    Toast.show({
                        icon: 'fail',
                        content: '支付失敗',
                    });
                }
            });
        if (typeof WeixinJSBridge == 'undefined') {
            console.log('WeixinJSBridge为undefind进入这里')
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
        } else {
            console.log('进入else')
            // onBridgeReady();
        }
    };

    if(!data || !Object.keys(data)?.length) {
        return null;
    }

    const monthStr = data.monthList.split(':').join('，');
    return (
        <>
            <div style={{ paddingBottom: '80px'}}>
                <Header />
                {/* 公告 */}
                <Notice />
                
                {/* 补缴社保卡片 */}
                <div className='marginT'>
                    <CustomeCard  title='补缴基础信息'>
                        <div className='confirm_info_item'>
                            <p>参保姓名</p>
                            <p>{data?.fullName}</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>补缴城市/区县</p>
                            <p>北京市</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>补缴基数</p>
                            <p>{data?.secuBaseValue / 100}</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>补缴月份数</p>
                            <p>{data?.monthSum}个月</p>
                        </div>
                        <div className='confirm_info_item'>
                            <p>补缴月份</p>
                            <p>{monthStr}</p>
                        </div>
                    </CustomeCard>
                </div>
                
                <div className='marginT'>
                    <CustomeCard  title='补缴费用明细'>
                        <Collapse defaultActiveKey={['1']}>
                            <Collapse.Panel key='1' title={
                                <div className='flexBetweenCenter'>
                                    <div>{`社保费+滞纳金（${data?.monthSum})个月`}</div>
                                    <div>￥{data?.socialAllFee / 100}</div>
                                </div>
                                
                            }>
                                <div className='detail_cont'>
                                    <div className='detail_cont_item'>社保费合计：￥{data?.socialSumFee / 100}</div>
                                    <div className='detail_cont_item'>滞纳金合计：￥{data?.socialLateSumFee / 100}</div>
                                    {
                                        !!data?.orderDetailVos.length && data?.orderDetailVos.map(item => {
                                            return (
                                                <>
                                                    <div className='detail_cont_item'>{item.socialMonth}, 社保费￥{item.socialSecuFee / 100}元，滞纳金￥{item.compenFee / 100}</div>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel key='2' title={
                                <div className='flexBetweenCenter'>
                                    <div>{`补缴服务费（${data?.monthSum})个月`}</div>
                                    <div>￥{data?.serviceSumFee / 100}</div>
                                </div>
                            }>
                                <div  className='detail_cont'>
                                    {
                                        !!data?.orderDetailVos.length && data?.orderDetailVos.map(item => {
                                            return (
                                                <>
                                                    <div className='detail_cont_item'>{item.socialMonth}, 补缴服务费￥{item.missionFee / 100}元</div>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </Collapse.Panel>
                        </Collapse>
                        <div className='flexBetweenCenter wxpay_line'>
                            <div>微信支付通道费（0.6%）</div>
                            <div>￥{data?.wxPayFee / 100}</div>
                        </div>
                    </CustomeCard>

                </div>
                <div className='marginT'>
                    <CustomeCard  title='补缴费用总计'>
                        <div className='confirm_info_item'>
                            <h4>费用总计</h4>
                            <p>￥{data?.orderTotalFee / 100}元</p>
                        </div>
                    </CustomeCard>
                </div>
                {/* <div><Button color='success' onClick={onFinish} className='confirm_button'>确认无误，提交订单并支付</Button></div> */}
                <div className='marginT'>
                    <CustomeCard  title='根据政策规定，补缴时注意事项'>
                        <ul className='noticeList'>
                            <li>1、可补缴的城市：北京;</li>
                            <li>2、可补缴的期限：近三个月;</li>
                            <li>3、社保补缴其数：可选;</li>
                            <li>4、补缴近三个月：每个月300元;</li>
                            <li>5、补缴三个月前：咨询客服;</li>
                            <li>6、社保补缴滞纳金：每天万分之五;</li>
                        </ul>
                    </CustomeCard>
                </div>
            
                {/* 备案号 */}
                <Contact />
                <div className='pay_box'>
                    <div className='amount'>实付款：<span className='red_color'>￥{data?.orderTotalFee / 100  ?? ''}</span></div>
                    <div className='pay_button' onClick={onFinish}>立即支付</div>
                </div>
            </div>
            {/* loading */}
            {
                showLoading && <div className='loading_box'>
                    <div className='loading_img_box'>
                        <Image src={IMGS.loadingImage} width={40} height={40} fit='cover' />     
                    </div>
                </div>
            }
        </>
        
    );
};
