import React from 'react';
import PropTypes from 'prop-types';

import { Card  } from 'antd-mobile';

import './style.scss';

export const CustomeCard = (props) => {
    const { title='' } = props;
    return (
        <div className='c_card'>
            <Card title={title}>
                {props.children}
            </Card>
        </div>
        
    );
};

CustomeCard.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element
};
