import React from 'react';
import { Image } from 'antd-mobile';

import './style.scss';


const IMGS = {
    weixin: require('../../images/c_weixin.jpg'),
    gongzhonghao: require('../../images/c_gongzhonghao_new.jpg'),
    ICP: require('../../images/ICP.jpg'),
    shield: require('../../images/shield.png')
};


export const Contact = () => {
    return (
        <div className='icons_layer'>
            <div className='beianhao'>
                <div className='beianhao_top'>
                    <span>——安全</span>
                    <Image src={IMGS.shield} width={30} height={36} fit='cover' style={{ margin: '0 8px'}} />
                    <span>便捷——</span>
                </div>
                <p>青蛙保微服务（青蛙社保）</p>
                <p style={{textAlign: 'center'}}>ICP备案：<br />京ICP备2023011490</p>
            </div>
            <div className='wei_icon'>
                <Image src={IMGS.weixin} width={60} height={60} fit='cover' />
                <p>加微信咨询</p>
            </div>
            <div className='wei_icon'>
                <Image src={IMGS.gongzhonghao} width={60} height={60} fit='cover' />
                <p>关注公共号</p>
            </div>
        </div>
    );
};
