import { Toast  } from 'antd-mobile';
// export const USER_ID = 10;
export const APPID = 'wxb6ad67f6fd30254a';
// export const REDIRECT_URI = 'http://qingwa.natapp1.cc/';
export const REDIRECT_URI = 'https://m.qingwaguagua.com';
export const SCOPE = 'snsapi_userinfo';
export const STATE = 'natapp1'

// export const getCurrentMonth = (date) => {
//     const year = date ? new Date(date).getFullYear() : new Date().getFullYear();
//     let month = date ? new Date(date).getMonth() + 1 : new Date().getMonth() + 1;
//     month = month < 10 ? '0' + month : month;

//     return year + '-' + month;
// };

// const getPreStepMonth = (step) => {
//     var today = new Date();
//     today.setMonth(today.getMonth()-step);
//     let day = getCurrentMonth(today);
//     return day;
// };

// export const getPreMonthList = (step) => {
//     const Mohth = [];

//     for(let i = 1; i<=step; i++) {
//         let stepMonth = getPreStepMonth(i);
//         Mohth.push(stepMonth);
//     }

//     return Mohth;
// };

export const getCurrentMonth = (date) => {
    const year = date ? new Date(date).getFullYear() : new Date().getFullYear();
    let month = date ? new Date(date).getMonth() + 1 : new Date().getMonth() + 1;
    month = month < 10 ? '0' + month : month;
  
    return year + '-' + month;
};
  
export const getPreMonthList = (step) => {
    const Month = [];
    const today = new Date();
  
    for (let i = 1; i <= step; i++) {
        const year = today.getFullYear(); // 获取当前年份
        const month = today.getMonth() + 1 - i; // 获取当前月份，并减去迭代次数

        if (month <= 0) { // 如果月份小于等于0，表示需要跨年
            const targetYear = year - 1;
            const targetMonth = 12 + month; // 修正目标月份为上一年的12月加上月份

            Month.push(`${targetYear}-${targetMonth < 10 ? '0' + targetMonth : targetMonth}`);
        } else {
            Month.push(`${year}-${month < 10 ? '0' + month : month}`);
        }
    }
  
    return Month;
};
  

export const domicileEnum = new Map([
    [1, '本市城镇户口'],
    [2, '外地城镇户口'],
    [37, '本市农村户口'],
    [4, '外地农村户口']
]);

export const socilStatus = new Map([
    [0, '未参保'],
    [1, '已参保'],
    [2, '已减员']
]);

export const socialType = {
    51: '社保',
    52: '公积金',
    53: '个税'
};

export const getFormatData = obj => {
    if(Object.prototype.toString.call(obj) !== '[object Object]'){
        return obj;
    }
    for(let prop in obj){
        if(Object.prototype.hasOwnProperty.call(obj, prop)){
            if(Object.prototype.toString.call(obj[prop]) === '[object Array]'){
                obj[prop] = obj[prop][0];
            } else if(Object.prototype.toString.call(obj[prop]) === '[object String]' || Object.prototype.toString.call(obj[prop]) === '[object Number]'){
                if(prop === 'secuBaseValue' || prop === 'housingBaseFee') { // 都是Number类型
                    obj[prop] = +obj[prop] * 100;
                } else {
                    obj[prop] = +obj[prop];
                }
            }
        }
    }
    return obj;
};

export const toast = (content) => {
    return (
        Toast.show({
            position: 'center',
            content: content,
            duration: 2000,
            // maskStyle: { width: '300px', height: '100%;'}
        })
    );
};

export const formDate = (time) => {
    var date = new Date(time);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second;

};

export const formatDate = (time, fmt) => {
    var date = new Date(time);
    var o = {
        'M+' : date.getMonth()+1,                 //月份
        'd+' : date.getDate(),                    //日
        'h+' : date.getHours(),                   //小时
        'm+' : date.getMinutes(),                 //分
        's+' : date.getSeconds(),                 //秒
        'q+' : Math.floor((date.getMonth()+3)/3), //季度
        'S'  : date.getMilliseconds()             //毫秒
    };
    
    if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (date.getFullYear()+'').substr(4 - RegExp.$1.length));
    for(var k in o)
        if(new RegExp('('+ k +')').test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (('00'+ o[k]).substr((''+ o[k]).length)));
    return fmt;
};


export const parseUrlParams = (url) =>{
    const params = {}
    if (!url || url === '' || typeof url !== 'string') {
        return params;
    }
    const paramsStr = url.split('?')[1]
    if (!paramsStr) {
        return params;
    }
    const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
    for (let i = 0; i < paramsArr.length / 2; i++) {
        const value = paramsArr[i * 2 + 1]
        params[paramsArr[i * 2]] =
        value === 'true' ? true : value === 'false' ? false : value
    }
    return params;
};

export const getUrlParms = (url, key) => {
    let obj = {};
    if(url) {
        obj = parseUrlParams(url);
    }

    if (Object.keys(obj).includes(key)) {
        return obj[key];
    }
    return undefined;
};

export const isWx = () => {
    let ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger';
};

export const getCookies = (name) => {
    try {
        let reg= new RegExp('(^| )' + encodeURIComponent(name) + '=([^;]*)(;|$)')
        let arr = document.cookie.match(reg);
        return arr ? arr[2] ? decodeURIComponent(arr[2]) : '' : null
    } catch(e) {
        return null
    }
}