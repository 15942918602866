import { createSlice } from '@reduxjs/toolkit';

export const userInfoSlice = createSlice({
    name: 'userId',
    initialState: {
        USER_ID: 10,
        nickName: '',
        headImgUrl: ''
    },
    reducers: {
        saveUser: (state, action) => {
            state.USER_ID = action.payload.id;
            state.nickName = action.payload.nickName;
            state.headImgUrl = action.payload.headImgUrl;
        }
    }
});
// 每个 case reducer 函数会生成对应的 Action creators
export const { saveUser } = userInfoSlice.actions;

export default userInfoSlice.reducer;