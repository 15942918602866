const toUrl = (obj) => {
    let i; let str = '';
    if (obj) {
        for (i in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, i)) {
                if (!obj[i] && typeof obj[i] !== 'number') { continue; }
                str += i + '=' + obj[i] + '&';
            }
        }
    }
    return str.slice(0, -1);
};

const request = (method, url, body, timeout) => {
    let host = 'https://m.qingwaguagua.com/lotus/';
    let config = {
        method,
        credentials: 'include', 
        headers: {
            'Accept': 'application/json, text/plain',
        }
    };

    if (body) {
        config.headers['Content-Type'] = 'application/json';
    }
    const timeoutPromise = timeout && new Promise((resolve, reject) => {
        timeout && setTimeout(reject, 10000, new TypeError('Network timeout'));
    });

    const promise = new Promise((resolve, reject) => {
       
        fetch(host + url, config).then(res => {

            if (res.status === 200 ) {
                return res.json();
            } else {
                reject(res);
                console.log('系统繁忙,请稍后再试!');
            }
        }).then((body) => {
            body ? resolve(body) : reject(body);
        }).catch((err) => {
            reject(err);
            console.log('系统繁忙,请稍后再试!');
        });
    });

    return timeout ? Promise.race([promise, timeoutPromise]) : promise;
};


export const get = (url, json, timeout) => {
    let str = json ? toUrl(json) : '';
    url = str ? url + '?' + str : url;
    return request('GET', encodeURI(url), null, timeout);
};

export const post = (url, json, timeout) => {
    let str = JSON.stringify(json);
    return request('POST', url, str, timeout);
};