import React, { useState, useEffect } from 'react';
import { Footer } from '../component/footer';
import { Header } from '../component/header';
import { Contact } from '../component/contacts';
import { Notice } from '../component/notice';
import { Policy } from '../component/policy';
import { Tour } from '../component/tour'; 
import { get } from '../common/fetch';

import { Image } from 'antd-mobile';

import './style.scss';

import { useNavigate  } from 'react-router-dom';

import { Dialog } from 'antd-mobile';
// import { HandPayCircleOutline, ReceivePaymentOutline } from 'antd-mobile-icons';

// const demoSrc =
//   'https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=60';

const IMGS = {
    b_shebao: require('../images/b_shebao.png'),
    b_gongjijin: require('../images/b_gongjijin.png'),
    
};

export const SupplementaryPaymentHomePage = () => {
    const navigate = useNavigate();
    const [notice, setNotice] = useState('');
    const [showNotice, setShowNotice] = useState(false);
    const gotoPage = (page) => {
        if (notice) {
            setShowNotice(true);
            return;
        }
        navigate(`/supplementaryPaymentHomePage/${page}`);
    };

    useEffect(() => {
        get('base/text').then((res) => {
            if (res.code === 200 && res.data){
                if(res.data) {
                    let noticeList = res.data.filter(item => item.type === '2');
                    noticeList.length && setNotice(noticeList[0].text);
                }
            } else {
                setNotice('');
            }
        });
    }, []);

    return (
        <div>
            <Header />
            <div style={{ paddingBottom: '80px'}}>
                {/* 补缴入口 */}
                <div className='main_icon'>
                    <div className='main_icon_item' onClick={()=>gotoPage('socialSecurity')}>
                        <Image src={IMGS.b_shebao} width={65} height={65} fit='cover'/>
                        {/* <HandPayCircleOutline color='#76c6b8' fontSize={36}/> */}
                        <div style={{paddingTop: '10px'}}><p>补缴社保</p><p><span>300元</span>月起</p></div>
                    </div>
                    <div className='main_icon_item' onClick={()=>gotoPage('accumulationFund')}>
                        <Image src={IMGS.b_gongjijin} width={65} height={65} fit='cover' />
                        {/* <ReceivePaymentOutline color='#76c6b8' fontSize={36}/> */}
                        <div style={{paddingTop: '10px'}}><p>补缴公积金</p><p><span>300元</span>月起</p></div>
                    </div>
                </div>
                {/* 公告 */}
                <Notice />
                {/* 政策解读 */}
                <Policy />
                {/* 指南icon */}
                <Tour />
            
                {/* 备案号 */}
                <Contact />
            </div>
            <Footer />
            {
                showNotice &&  <Dialog
                    title='温馨提示'
                    visible={showNotice}
                    content={notice}
                    closeOnAction
                    onClose={() => {
                        setShowNotice(false);
                    }}
                    actions={[
                        [
                            {
                                key: 'cancel',
                                text: '再想想',
                                style:React.CSSProperties  = { color: '#666' }
        
                            },
                            {
                                key: 'confirm',
                                text: '自动缴纳',
                                onClick: ()=>{navigate('/renew/socialSecurityRenew');}
                            }
                        ]
                    ]}
                />
            }
        </div>
    );
};
