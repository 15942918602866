import React from 'react';
import { Button, Image } from 'antd-mobile';
import { useNavigate, useLocation  } from 'react-router-dom';
import './style.scss';

const IMGS = {
    success: require('../images/success.png'),
};
export const PaySuccessPage = () => {
    const { state } = useLocation();
    const { price } = state || {};
    const navigate = useNavigate();
    const goHome = () => {
        navigate('/');
    }


    return (
        <div >
            <div className='successTopBox'>
                <Image src={IMGS.success} width={30} height={30} style={{ marginRight: '10px'}} />
                <span>支付成功</span>
            </div>
            <div className='successMiddleBox'>
                <div>青蛙电子商务</div>
                {
                    !!price && <div className='successMiddleBox_price_box'>￥<span className='successMiddleBox_price'>18.19</span></div>
                }
            </div>
            
            <Button color='success' style={{width: '80%', marginLeft:' 10%', marginTop: '120px'}} onClick={goHome}>完成</Button>
        </div>
    )
}