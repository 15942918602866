import React, {  } from 'react';

import PropTypes from 'prop-types';

import { Picker } from 'antd-mobile';
import { AddPerson } from '../pick-person';

export const CustomePicker = (props) => {
    const { basicColumns, visible, setVisible, onConfirm, value, title='', type, reloadPerson} = props;

    if(type === 'person' && ((basicColumns && basicColumns?.length < 1))) {
        return (
            <AddPerson visible={visible}  onClose={setVisible} reloadPerson={reloadPerson}/>
        )
    } 

    return (
        <Picker
            title={title}
            columns={basicColumns}
            visible={visible}
            onClose={setVisible}
            value={value}
            onConfirm={(v,e) => {
                onConfirm(v,e);
            }}
        />
    );
};

CustomePicker.propTypes = {
    basicColumns: PropTypes.array,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    onConfirm: PropTypes.func,
    value: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    reloadPerson: PropTypes.func,
};
