// 补缴社保
import React, { useState } from 'react';
import { Footer } from '../component/footer';
import { Header } from '../component/header';
import { Contact } from '../component/contacts';
import { Notice } from '../component/notice';
import { CustomeCard } from '../component/custome-card';
import { CardForm } from './CardForm';

import { useNavigate  } from 'react-router-dom';
import { Toast } from 'antd-mobile';

import './style.scss';

import { toast, getFormatData } from '../common/utils';
import { useSelector } from 'react-redux';
import {get} from '../common/fetch';

export const AccumulationFund  = () => {
    const { USER_ID } = useSelector(state=>state.userInfo);
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);

    const onFinish = (values) => {
        if(!userId) {
            toast('请选择参保人');
            return;
        }
        if(!values.item) {
            toast('请选择补缴项目');
            return;
        }
        if(!values.housingBaseFee) {
            toast('请选择补缴基数');
            return;
        }
        if(!values.month) {
            toast('请选择补缴月份');
            return;
        }
        const formatData = getFormatData({...values});
        console.log('values==formatData', formatData);
        formatData.secuPersonId = formatData.secuPersonId.split('_')[1];
        formatData.userId = USER_ID;
        formatData.isArrearsPay = 1;
        formatData.isNewSocial = 0;
        formatData.monthList = values.month.join(':');

        get('order/cal', formatData).then((res) => {
            if (res.code === 200 && res.data){
                // navigate('/supplementaryPaymentHomePage/accumulationFundConfirm',  { state: {data: res.data,  formatData: formatData}});
                navigate('/notice', { state: {data: res.data,  formatData: formatData, router: '/supplementaryPaymentHomePage/accumulationFundConfirm'}});
            } else {
                Toast.show({
                    icon: 'fail',
                    content: '保存失败',
                });
            }
        });
    };

    const choosePerson = (v) => {
        console.log('请选择', v);
        setUserId(v);
    };

    return (
        <div>
            <Header />
            {/* 公告 */}
            <Notice />
            <div style={{paddingBottom: '80px'}}>
                {/* 补缴社保卡片 */}
                <div className='social_box'>
                    <p><span className='social_title'>补缴，公积金</span></p>
                    <CardForm onFinish={onFinish} choosePerson={choosePerson} type='gongjijin' />      
                </div>
                <div className='marginT'>
                    <CustomeCard  title='根据政策规定，补缴时注意事项'>
                        <ul className='noticeList'>
                            <li>1、可补缴的城市：北京;</li>
                            <li>2、可补缴的期限：近三个月;</li>
                            <li>3、社保补缴其数：可选;</li>
                            <li>4、补缴近三个月：每个月300元;</li>
                            <li>5、补缴三个月前：咨询客服;</li>
                            <li>6、社保补缴滞纳金：每天万分之五;</li>
                        </ul>
                    </CustomeCard>
                </div>
                {/* 备案号 */}
                <Contact />
            </div>
            <Footer />
        </div>
    );
};
