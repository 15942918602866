import React from 'react';
import './style.scss';
import { Image, Button } from 'antd-mobile';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../component/header';

const IMGS = {
    notice: require('../images/notification.png'),
    stick: require('../images/sticky-notes.png')
};

export const NoitcePage = () => {
    const { state } = useLocation();
    const { data, formatData, router } = state || {};
    const navigate = useNavigate();

    const goNextPage = () => {
        navigate( router, {state: { data, formatData }});
    };
    
    return (
        <div>
            <Header />
            <div className='box'>
                <div className='noticeItem'>
                    <div className='titleBox'><Image src={IMGS.notice} width={30} height={30} />&nbsp;&nbsp;重要说明必读</div>
                    <p><span className='dot' />&nbsp;&nbsp;第一次续缴社保前，请先确认前单位是否已经减员</p>
                    <p><span className='dot' />&nbsp;&nbsp;本平台不可办理手工报销<span className='import'>生育/医疗/工伤</span>等，疑似或确认患有重大疾病的情况不可办理，不可办理失业金领取</p>
                    <p><span className='dot' />&nbsp;&nbsp;公民在拘留、羁押期间不可办理</p>
                
                </div>
                <div className='noticeItem mt40 space_line'>
                    <div className='titleBox'><Image src={IMGS.stick} width={30} height={30} />&nbsp;&nbsp;社保减员注意事项</div>
                    <p><span className='dot' />&nbsp;&nbsp;若您停止续缴后，本平台通常会于本次续缴截止时间后的一周内操作减员（部分地区可能会有延迟）</p>
                </div>
                
            </div>
            <div style={{ position: 'fixed', bottom: 0, width: '100%'}}>
                <Button color='success' onClick={goNextPage} className='button'>我已知晓</Button>
            </div>
        </div>
    );
};
